$(function(){
    $('.tabs nav li').click(function(){
        var tabIndex = $(this).index();
        $('.tabs nav li').removeClass('active');
        $(this).addClass('active'); 

        $('.tabs .tabs-grid .tab').removeClass('active');
        $('.tabs .tabs-grid .tab').eq(tabIndex).addClass('active');

    });
});