$(function() {   

    // Home Banner Slider
    if ($('.slideSet').length > 0) {
        $('.slideSet').flickity({
            // options
            cellAlign: 'left',               
            wrapAround: true,    
            groupCells: "100%",    
            contain: true,
            pageDots: true,
            prevNextButtons: true,  
            imagesLoaded:true,
            adaptiveHeight: true,
            autoPlay: 5000,
            //autoplaySpeed: 3000, 
        });  
        
        var testimonials = $('.slideSet');
          testimonials.on( 'select.flickity', function( event, index ) {
            $('.slider').find('video').each(function() {
                this.pause();
            });
        });
    };      

    // Group Slider
    if($('.groupSlider').length > 0)
	{
        $('.groupSlider').flickity({
            cellAlign: 'left',
            wrapAround: true,
            groupCells: "true",
            contain: true,
            pageDots: true,
            prevNextButtons: true,
            imagesLoaded: true,
            adaptiveHeight: true,
        });
    }; 

    // Group Slider 2
    if($('.brandAdvertSlider').length > 0)
    {
        $('.brandAdvertSlider').flickity({
        cellAlign: 'left',
        wrapAround: true,
        groupCells: false,
        contain: true,
        pageDots: true,
        prevNextButtons: true,
        imagesLoaded: true,
        adaptiveHeight: true,
        autoPlay: 1500,
        autoplaySpeed: 2000,
        });
    }; 


    if($('#brandCommercials').length > 0)
    {
        $('#brandCommercials').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 16000,
            pauseOnHover: false,
            dots: false,
            arrows:false,
            cssEase: 'linear',            

            responsive: [
                {
                  breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,                    
                  }
                }                
              ]
        });
    };     
     
})


