$(function () {
    // FancyBox 
    $('[data-fancybox]').fancybox({
        loop : true,
        keyboard : true,
        arrows : true,      
        toolbar : true,
        protect : true,
        animationEffect : "zoom",     
        
        buttons : [ 
          'close'
          ], 

        youtube : {
            controls : 1,
            showinfo : 0
        },
        vimeo : {
            color : 'f00'
        }

      });
      
}); 



