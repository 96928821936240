function smp(a){
    //alert(a);
        // $(this).toggleClass("active");
        $(".management-info"+a).toggleClass("open-info");
        // $(".management-info").toggle("slide", { direction: "down" }, 1000);

        $(".management-info"+a+" .hidden").show(1000);
        //$(".block").removeClass('hidden');
        $(".sm"+a).hide();
        $(".sl"+a).show();
        $(".sl"+a).addClass('flex');    
    }
     function slp(a){       
        $(this).toggleClass("active");
        $(".management-info"+a).toggleClass("open-info");
        // $(".management-info").toggle("slide", { direction: "down" }, 1000);
       
        $(".hidden").hide(1000);
        //$(".block").removeClass('hidden');
        $(".sm"+a).show();
        $(".sl"+a).hide();
        $(".sl"+a).removeClass('flex');    
    } 