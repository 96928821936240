$(function () { 
    
    // ACCORDION Toggle (Multiple Items)   
    if($('.acc-row .accordion-head').length > 0)
    {
        $('.acc-row .accordion-head').click(function(){
            var parent = $(this).parent();        
            parent.toggleClass('active');
            parent.find('.accordion-info').stop().slideToggle();
        });
    }

    // ACCORDION (Multiple Items)
    $('.accordion-row .accordion-head').click(function(){
        var parent = $(this).parent();
        parent.siblings().find('.accordion-info').hide();
        parent.siblings().removeClass('active');
        parent.toggleClass('active');
        parent.find('.accordion-info').stop().slideToggle();
    });
}); 

