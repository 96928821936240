$(function(){
    $('.top-search').click(function(){
        if(!$(this).hasClass('active')){
            $(this).addClass('active');
            $('div#searchBar').slideDown();
        }else{
            $(this).removeClass('active');
            $('div#searchBar').slideUp();
        }
    });

    $('.top-search').click(function(){        
        var pageTop = document.getElementById("header-gbl");
        pageTop.scrollIntoView({behavior: 'smooth'});
        $('div#searchBar input[type=text]').focus();
    });
});