$(function(){    
   $(".wrapper-links nav li.has-sub").on("mouseenter", function () {
       $(".wrapper-links").addClass("open") 
   })

   $(".wrapper-links nav li.has-sub").on("mouseleave", function () {
       $(".wrapper-links").removeClass("open")
   })
});

// For Mobile Navigation
$(function(){
   $(".hamburger-menu").on("click", function () {
       $("body").toggleClass("mobileMenuOpen");
   });
   
   $(".close-menu").on("click", function () {
       $("body").toggleClass("mobileMenuOpen");
   
       $(".submenu-toggle.open").removeClass("open") 
       //$(".l0__mobile.open").removeClass("open");
       $(".mobile-menu-sub.open").removeClass("open")
   })

   $(".submenu-toggle").on("click", function () {
       const targetSubMenu = $(this).data("menu-target");
       const subMenu = $(`.mobile-menu-sub[data-menu="${targetSubMenu}"]`);
       subMenu.addClass("open");
   })
   
   $(".menu-sub-prev").on("click", function () {
       const clickedIndex = $(this).index(".menu-sub-prev");
       $(".mobile-menu-sub.open").removeClass("open")
   })    
});